<template>
  <nav>
    <v-toolbar>
      <v-app-bar-nav-icon class="grey--text" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="grey--text">
        <span class="font-weight-light">Lecture</span>
        <span>3</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <!-- dropdown menu -->
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
        <!-- <v-btn text slot="activator"> -->
        <v-btn text v-on="on">
          <v-icon left>expand_more</v-icon>
          <span>Menu</span>
        </v-btn>
        </template>
        <v-list>
          <!-- v-list-tile is changed to v-list-item -->
          <v-list-item v-for="link in links" :key="link.text" router :to="link.route">
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn text>
        <span>Sign Out</span>
        <v-icon right>exit_to_app</v-icon>
      </v-btn>
    </v-toolbar>

    <v-navigation-drawer v-model="drawer" app class="primary">
      <v-list>
        <!-- v-list-tile is changed to v-list-item -->
        <v-list-item v-for="link in links" :key="link.text" router :to="link.route">
          <v-list-item-action>
            <v-icon class="white--text">{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content style="text-align: left;">
            <v-list-item-title class="white--text">{{ link.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

  </nav>
</template>


<script>
export default {
  data: () => ({
    drawer: false,
    links: [
      { icon: 'home', text: 'Home', route: '/'},
      { icon: 'menu_book', text: 'Oliver Twist', route: '/olivertwist/contents'},
      { icon: 'menu_book', text: 'La Petite Fadette', route: '/lapetitefadette/contents'},
      { icon: 'menu_book', text: 'SOUVENIRS ENTOMOLOGIQUES Livre I', route: '/SOUVENIRS ENTOMOLOGIQUES Livre I/contents'},
      { icon: 'menu_book', text: 'The formation of vegetable mould', route: '/The formation of vegetable mould/contents'},
    ]
  }),
}
</script>
