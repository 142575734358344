<template>
  <v-app id="app">
    <Navbar />
    <v-main>
      <router-view></router-view>
    </v-main>

    <v-footer
      padless
      height="1px"
      class="grey lighten-5"
      elevation="1"
    >
      <v-col
        class="text-center"
      >
        <p class="grey--text caption">
          &copy; 2020 - <a href="https://atelierueda.uedasoft.com/">Atelier UEDA</a>
        </p>
      </v-col>
    </v-footer>    
  </v-app>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.home{
  text-align: left;
}
p {
  text-indent: 1em;
}

</style>

<script>
import Navbar from '@/components/Navbar'
export default {
  components: { Navbar },
}
</script>