<template>
  <div>
    <h2>はじめに</h2>
    <p>lecture3 は私の趣味の読書のためのサイトです</p>

    <h2>私の読み方</h2>
    <p style="text-align: left;">語彙の貧弱さゆえに頻繁に辞書を引かなければならない事が私が読書を楽しむ事の妨げになっていました。日本語のある単語がカバーする概念と、別の言語の類似の単語がカバーする概念は onto ではないのでコンテキストにフィットする訳語を選択していかないと意味が通じないのですが、そもそもコンテキストを理解するにも語彙が不足するような状態なので引いている間に先に引いた語の訳語を忘れる有様で、読書を困難にしていました。</p>
    <p>そこで、読みたい本のテクストをまずコピーしておき、辞書を引いた時は自分が適切と考えた約語をテクストの中に埋め込んでおくようにして、読み返す時には単語をタッチしただけで自分の選択した訳語を確認できるようにすることでコンテキストの理解と訳語の選択が同時進行するようにしています。
      本サイトの単語のリンクの多さはまさに現時点での私の語彙の貧弱さの証であります
    </p>
    <p style="text-align: left;">lecture3 では私の好みで、著作権がすでに切れていているものを選択しています。今の所、私が読書に利用できる言語が英語とフランス語しかないのでその２つから選んでいます</p>

    <h3>News</h3>
    <div style="text-align: left;">
      2020.11.18<br>

      次の４冊から読み始める事にします<br>
      <ul>
        <li>
          <router-link
            to='/olivertwist/contents'
          >
          オリバー・ツイスト（チャールズ・ディッケンズ）[英]
          </router-link>
        </li>
        <li>
          <router-link
            to='/lapetitefadette/contents'
          >
          ラ・プティット・ファデット（ジョルジュ・サンド）[仏]
          </router-link>
        </li>
        <li>
          <router-link
            to='/SOUVENIRS ENTOMOLOGIQUES Livre I/contents'
          >
          昆虫記（アンリ・ファーブル）[仏]
          </router-link>
        </li>
        <li>
          <router-link
            to='/The formation of vegetable mould/contents'
          >
          ミミズによる土壌の形成（チャールズ・ダーウィン）[英]
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<style>
</style>

<script>
//import Tooltip from '@/components/Tooltip'
export default {
  name: 'Home',
//  components: {Tooltip},
}
</script>
